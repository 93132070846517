export const UserData  = [
  {
    id: 1,
    userGain: 65,
    color: "#efefef",
  },
  {
    id: 2,
    userGain: 59,
    color: "#efefef",
  },
  {
    id: 3,
    userGain: 80,
    color: "#fcb602",
  },
  {
    id: 4,
    userGain: 81,
    color: "#efefef",
  },
  {
    id: 5,
    userGain: 56,
    color: "#efefef",
  },
  {
    id: 6,
    userGain: 65,
    color: "#efefef",
  },
  {
    id: 7,
    userGain: 59,
    color: "#ffe921",
  },
  {
    id: 8,
    userGain: 80,
    color: "#efefef",
  },
  {
    id: 9,
    userGain: 81,
    color: "#efefef",
  },
  {
    id: 10,
    userGain: 56,
    color: "#efefef",
  },
  {
    id: 11,
    userGain: 56,
    color: "#efefef",
  },
  {
    id: 12,
    userGain: 65,
    color: "#efefef",
  },
]