import React, {useState,useEffect} from 'react';
import { NavLink } from "react-router-dom";
import Aos from 'aos';
import axios from './config/axios';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
const blogImg1 = require("./images/blog-img1.png");
const blogThumbImg1 = require("./images/blog-thumb-img1.png");
const blogThumbImg2 = require("./images/blog-thumb-img2.png");
const blogThumbImg3 = require("./images/blog-thumb-img3.png");


const Blog = () => { 
    const [featuredBlog, setFeaturedBlog] = useState(null);
    const [blogs,setBlogs] = useState([]);
    useEffect( () => {
        Aos.init();

        axios.get("/blogs")
        .then(response => {
            const {data} = response.data;
            const featured = data.filter(d => d.is_featured == 1)[0];
            const others = data.filter(d => d.is_featured != 1);
            setFeaturedBlog(featured);
            setBlogs(others);
        })




    }, []);
    return (
        <>
            {featuredBlog ? <div className='blogSectionOne'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 imgConetnt' data-aos='fade-right' data-aos-delay="200" data-aos-offset="100"><NavLink to={"/BlogDetails/" + featuredBlog.id}><img src={featuredBlog.image} alt="img" /></NavLink></div>
                        <div className='col-lg-6 textContent' data-aos='fade-left' data-aos-delay="200" data-aos-offset="100">
                            <h4>{featuredBlog.created_at}</h4>
                            <h3><NavLink to={"/BlogDetails/" + featuredBlog.id}>{featuredBlog.title}</NavLink></h3>
                            <div dangerouslySetInnerHTML={{__html:featuredBlog.description.substr(0,27)+'...'}}></div>
                            <NavLink to={"/BlogDetails/" + featuredBlog.id} className='btn btn1'>Read More</NavLink>
                        </div>
                    </div>
                </div>
            </div>  : null}
            <div className='blogSectionTwo'>
                <div className='container'>
                    <ul className='blogList row' style={{marginTop:10}}>
                        {blogs.map(blog => <li className='col-lg-4'>
                            <div className='imgConetnt'><NavLink to={"/BlogDetails/" + blog.id}><img style={{minHeight:300,maxHeight:300}} src={blog.image} alt="img" /></NavLink></div>
                            <div className='textContent'>
                                <h3><NavLink to={"/BlogDetails/" + blog.id}>{blog.title.substr(0,27)}...</NavLink></h3>
                                <p>
                                   ...
                                    <NavLink to={"/BlogDetails/" + blog.id} className='btn'>[more]</NavLink></p>
                            </div>
                        </li>)}
                        
                       
                    </ul>
                   <div style={{height:500,width:500,maxHeight:500,overflow:'scroll',margin:'0 auto'}}>
                   <TwitterTimelineEmbed
                    sourceType="profile"
                    screenName="noblesportsmgmt"
                    options={{height: 500}}
                    />
                   </div>
                </div>
            </div>
        </>
    );
}

export default Blog;