export const UserData = [
    {
      "id": 1,
      "firstname": "Jaylnn",
      "lastname": "Bristow",
      "class": 2023,
      "school": "Flower Mound",
      "address": "Rosharon",
      "phonenumber": 9727467197,
      "rating": 4.5,
      "rank": 4,
      "position": "SF",
      "state": "TX",
      socialMedia: [
        {
             twitterIcon: require("./images/twitter-icon.png"),
             fbIcon: require("./images/fb-icon.png"),
             igIcon: "",
             playIcon: "" 
         }
       ]
    },
    {
      "id": 2,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "North Carolina",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 4.5,
      "rank": 2,
      "position": "PG",
      "state": "AK, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: "",
             igIcon: require("./images/ig-icon.png"),
             playIcon: ""
         }
       ]
    },
    {
      "id": 3,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Texas A&M-Corpus Christi",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 4.5,
      "rank": 1,
      "position": "C",
      "state": "AK, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: "",
             igIcon: "",
             playIcon: ""
         }
       ]
    },
    {
      "id": 4,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Arkansas",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 4.5,
      "rank": 1,
      "position": "SF",
      "state": "AR, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: "",
             igIcon: require("./images/ig-icon.png"),
             playIcon: ""
         }
       ]
    },
    {
      "id": 5,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Kansas",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 4,
      "rank": 3,
      "position": "PG",
      "state": "CA, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: "",
             igIcon: "",
             playIcon: ""
         }
       ]
    },
    {
      "id": 6,   
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Alabama",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 5,
      "rank": 1,
      "position": "PG",
      "state": "CO, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: "",
             igIcon: require("./images/ig-icon.png"),
             playIcon: ""
         }
       ]
    },
    {
      "id": 7, 
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": 2023,
      "school": "North Carolina",
      "address": "Rosharon",
      "phonenumber": 9727467197,
      "rating": 4.5,
      "rank": 1,
      "position": "C",
      "state": "CT, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: require("./images/fb-icon.png"),
             igIcon: "",
             playIcon: ""
         }
       ]
    },
    {
      "id": 8,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Arkansas",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 3.5,
      "rank": 4,
      "position": "SF",
      "state": "AL, USA",
      socialMedia: [
        {
             twitterIcon: require("./images/twitter-icon.png"),
             fbIcon: "",
             igIcon: require("./images/ig-icon.png"),
             playIcon: ""
         }
       ]
    },
    {
      "id": 9,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Kansas",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 3.5,
      "rank": 2,
      "position": "PG",
      "state": "DE, USA",
      socialMedia: [
        {
             twitterIcon: require("./images/twitter-icon.png"),
             fbIcon: require("./images/fb-icon.png"),
             igIcon: "",
             playIcon: ""
         }
       ]
    },
    {
      "id": 10,
      "firstname": "Cierra",
      "lastname": "Bristow",
      "class": "XII",
      "school": "Alabama",
      "address": "1582 Bluff Street",
      "phonenumber": 2053912306,
      "rating": 3.5,
      "rank": 2,
      "position": "PG",
      "state": "FL, USA",
      socialMedia: [
        {
             twitterIcon: "",
             fbIcon: "",
             igIcon: require("./images/ig-icon.png"),
             playIcon: ""
         }
       ]
    },
    {
        "id": 11,
        "firstname": "Jaylnn",
        "lastname": "Bristow",
        "class": 2023,
        "school": "Flower Mound",
        "address": "Rosharon",
        "phonenumber": 9727467197,
        "rating": 4.5,
        "rank": 4,
        "position": "SF",
        "state": "TX",
        socialMedia: [
          {
               twitterIcon: require("./images/twitter-icon.png"),
               fbIcon: require("./images/fb-icon.png"),
               igIcon: "",
               playIcon: "" 
           }
         ]
      },
      {
        "id": 12,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "North Carolina",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 4.5,
        "rank": 2,
        "position": "PG",
        "state": "AK, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: "",
               igIcon: require("./images/ig-icon.png"),
               playIcon: ""
           }
         ]
      },
      {
        "id": 13,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Texas A&M-Corpus Christi",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 4.5,
        "rank": 1,
        "position": "C",
        "state": "AK, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: "",
               igIcon: "",
               playIcon: ""
           }
         ]
      },
      {
        "id": 14,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Arkansas",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 4.5,
        "rank": 1,
        "position": "SF",
        "state": "AR, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: "",
               igIcon: require("./images/ig-icon.png"),
               playIcon: ""
           }
         ]
      },
      {
        "id": 15,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Kansas",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 4,
        "rank": 3,
        "position": "PG",
        "state": "CA, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: "",
               igIcon: "",
               playIcon: ""
           }
         ]
      },
      {
        "id": 16,   
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Alabama",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 5,
        "rank": 1,
        "position": "PG",
        "state": "CO, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: "",
               igIcon: require("./images/ig-icon.png"),
               playIcon: ""
           }
         ]
      },
      {
        "id": 17, 
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": 2023,
        "school": "North Carolina",
        "address": "Rosharon",
        "phonenumber": 9727467197,
        "rating": 4.5,
        "rank": 1,
        "position": "C",
        "state": "CT, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: require("./images/fb-icon.png"),
               igIcon: "",
               playIcon: ""
           }
         ]
      },
      {
        "id": 18,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Arkansas",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 3.5,
        "rank": 4,
        "position": "SF",
        "state": "AL, USA",
        socialMedia: [
          {
               twitterIcon: require("./images/twitter-icon.png"),
               fbIcon: ".",
               igIcon: require("./images/ig-icon.png"),
               playIcon: ""
           }
         ]
      },
      {
        "id": 19,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Kansas",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 3.5,
        "rank": 2,
        "position": "PG",
        "state": "DE, USA",
        socialMedia: [
          {
               twitterIcon: require("./images/twitter-icon.png"),
               fbIcon: require("./images/fb-icon.png"),
               igIcon: "",
               playIcon: ""
           }
         ]
      },
      {
        "id": 20,
        "firstname": "Cierra",
        "lastname": "Bristow",
        "class": "XII",
        "school": "Alabama",
        "address": "1582 Bluff Street",
        "phonenumber": 2053912306,
        "rating": 3.5,
        "rank": 2,
        "position": "PG",
        "state": "FL, USA",
        socialMedia: [
          {
               twitterIcon: "",
               fbIcon: "",
               igIcon: require("./images/ig-icon.png"),
               playIcon: ""
           }
         ]
      }
  ]
  