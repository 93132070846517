import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import axios from './config/axios'

const logo = require("./images/logo.png");
const modalCross = require("./images/popup_close_icon.png");
const leftImage = require('./images/mblmb.png');


const Header = () => {
  const [show, setShow] = useState(false);
  const [user,setUser] = useState({email:'',password:''});
  const [currentUser,setCurrentUser] = useState(null);
  const [currentPakage,setCurrentPackage] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProcessLogin = (event) => {
    event.preventDefault();
    axios.post("/login",user)
    .then(response => {
      const {token,user,profile} = response.data;
      const currentUser = {token,user,profile};
      if(parseInt(currentUser.user.status) === 0){
        localStorage.setItem("currentUser",JSON.stringify(currentUser));
        localStorage.removeItem("currentPackage");
          window.location="/Dashboard"
        
      }else{
        alert("Wrong username or password");
      }
    }).catch(error => {
      alert("Wrong username or password");
    })
  }

  useEffect(() => {
      const _currentUser = JSON.parse(localStorage.getItem("currentUser"));
      const _currentPackage = JSON.parse(localStorage.getItem("currentPackage"));
      if(_currentUser){
          setCurrentUser({...currentUser});
          if(_currentPackage){
          setCurrentPackage({..._currentPackage});
          }
      }

  },[]);

  const changeHandler  = (event,source) => {
    const _user =  {...user};
    _user[source] = event.target.value;
    setUser({..._user});
  }

  const handleLogout = () => {
    localStorage.removeItem("currentUser");
    setCurrentUser(null);
  }


    return (
      <>
        <Navbar bg='dark' expand='lg' className='header'>
          <Container>
            <NavLink to='/'><img src={logo} alt='img' /></NavLink>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='ms-auto'>
                <NavLink to='/about' className='nav-link'>About us</NavLink>
                <NavLink to='/Services' className='nav-link'>Services</NavLink>
                <NavLink to='/Blog' className='nav-link'>Blog</NavLink>
                <NavLink to='/Contact' className='nav-link'>Contact</NavLink>
                {currentUser && !currentPakage && <NavLink to='/dashboard' className='nav-link'>Reports</NavLink>}
                {currentUser && !currentPakage ?  <NavLink to='/about' className='nav-link loginLink' onClick={handleLogout}>Logout</NavLink> : <NavLink to='/about' className='nav-link loginLink' onClick={handleShow}>Login</NavLink>}
                
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Modal show={show} onHide={handleClose} className='loginModal'>
          <Modal.Body>
          <button onClick={handleClose} className='closeModalBtn'><img src={modalCross} alt="img" /></button>
            <h3>Login</h3>
            <ul className='loginList'>
              <li><input type="email" value={user.email} onChange={event => changeHandler(event,'email')} className="form-control" placeholder="Email/Username" /></li>
              <li><input type="password" value={user.password} onChange={event => changeHandler(event,'password')} className="form-control" placeholder="Password" /></li>
              <li>
                <div className='row'>
                  <div className='col-lg-6 btnSection'>
                    {/* <input type="submit" value="Login" className='btn' /> */}
                    <NavLink to="/Dashboard" className='btn' onClick={event => handleProcessLogin(event)}>Login</NavLink>
                  </div>
                  <div className='col-lg-6 forgotSection'><NavLink to="/ForgotPassword" className='ForgotPassword' onClick={handleClose}>Forgot Password</NavLink></div>
                </div>
              </li>
            </ul>
            {/* <p className='createNow'>Don't have an account yest? <NavLink to="/Register" onClick={handleClose}>Create Now!</NavLink></p> */}
          </Modal.Body>
        </Modal>
      </>
    );
  }
  
  export default Header;