import React, {useState, useEffect} from 'react';
import axios from './config/axios';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const cardImage = require("./images/card-img.png");
const paypalImage = require("./images/paypal-img.png");



const PaymentMethod = () => { 
    const [months,setMonths] = useState([]);
    const [years,setYears] = useState([]);
    const [card,setCard] = useState({card_number:'',month:'',year:'',cvv:'',name_card:''});
    const [loading,setLoading] = useState(null);
    const [currentPackage,setCurrentPackage] = useState(null);

    const changeHandler = (event,source) => {
        const _card = {...card};
        _card[source] = event.target.value;
        setCard({..._card});
    }


    const submitHandler = (event) => {
        event.preventDefault();
      
        processPayment();
    }

    const processPayment = () => {
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        const otherinfo = JSON.parse(localStorage.getItem("otherinfo"));
        const packageInfo = JSON.parse(localStorage.getItem("currentPackage"));
        const userid = currentUser.user.id;
       
        // setCard({...card,user_id:currentUser.user.id,amount:otherinfo.amount,package_id:packageInfo.id});
        const ourCard = {...card,package_id:packageInfo.id,user_id:currentUser.user.id,amount:otherinfo.amount};
        setLoading("Please wait your payment is processing....");
        axios.post("/stripe",{...ourCard})
        .then(response => {
            setLoading(null);
            if(currentUser.user.role_id == 3){
                console.log(ourCard);
                window.location="/Dashboard"
            }else{
                window.location="/ThankPayment"
            }
        }).catch(error => {
            setLoading(null);
            console.log(error);
        })
    }

    const fillData = () => {
        const _months = [];
        for(let i = 1;i<=12;i++){
            _months.push(i);
        }
        setMonths([..._months]);
        const _years = [];

        for(let i = 1990;i<=2040;i++){
            _years.push(i);
        }

        setYears([..._years]);

    }

    // creates a paypal order
 const createOrder = (data, actions) => {
    const _otherinfo = JSON.parse(localStorage.getItem("otherinfo"));
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Subscription purchased",
            amount: {
              currency_code: "USD",
              value: _otherinfo.amount,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: "NO_SHIPPING",
        },
      })
      .then((orderID) => {
        // setOrderID(orderID);
        return orderID;
      });
  };
  
  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
        const currentUser = JSON.parse(localStorage.getItem("currentUser"));
        const packageInfo = JSON.parse(localStorage.getItem("currentPackage"));
        axios.post("/paypal",{user_id:currentUser.user.id,package_id:packageInfo.id})
        .then(response => {
            localStorage.removeItem("currentPackage");
            if(currentUser.user.role_id == 3){

                window.location="/Dashboard"
            }else{
                localStorage.removeItem("currentUser");
               
                localStorage.setItem("purchasePackagePrice",packageInfo.price);
                window.location="/ThankPayment"
            }
        })
    });
  };

    useEffect(() => {
        fillData();
        const currentPackage = JSON.parse(localStorage.getItem("currentPackage"));
        setCurrentPackage({...currentPackage});
       
    },[])

    
    return (
        <>
        <PayPalScriptProvider
     options={{
       "client-id":"AYphWh_ZMwlMG2OQbJ0uDl9S6QMwoABpC4sbNS962IbTktXUcTQRpmvdPoSnRfoqRg9SzuP4YAtc-K4p",
     }}
   >
            <div className='paymentBillingSection'>
                <div className='container'>
                    <div className='row'>
                        <div  className='col-lg-8 leftBillingSection'>
                            {/* <h1>Payment Method</h1> */}
                            {/* <h4>Pay with card: <span className='cardImg'><img src={cardImage} alt="img" /></span></h4> */}
                            <ul style={{display:'none'}} className='billingList row'>
                                <li className='col-lg-6'><input type="text" value={card.card_number} onChange={event => changeHandler(event,'card_number')} className="form-control" placeholder="Card Number" /></li>
                                <li className='col-lg-6'><input type="text" value={card.name_card} onChange={event => changeHandler(event,'name_card')} className="form-control" placeholder="Name on Card" /></li>
                                <li className='col-lg-4'>
                                    <select onChange={event => changeHandler(event,'month')}  className="form-select" aria-label="Default select example">
                                        <option>Select Month</option>
                                        {months.map(month => <option value={month}>{month}</option>)}
                                    </select>
                                </li>
                                <li className='col-lg-4'>
                                    <select onChange={event => changeHandler(event,'year')} className="form-select" aria-label="Default select example">
                                        <option selected>Exp yyyy</option>
                                        {years.map(year => <option value={year}>{year}</option>)}
                                    </select>
                                </li>
                                <li className='col-lg-4'>
                                    <input type="text" value={card.cvv} onChange={event => changeHandler(event,'cvv')} className="form-control" placeholder="Cvv Number" />
                                    </li>
                                {/* <li className='col-lg-5'><button type="Submit" class="btn btn-primary btn1">Save and Proceed to Payment</button></li> */}
                                <li className='col-lg-5'>{loading? loading : <Link onClick={event => submitHandler(event)} to='/ThankPayment' class="btn btn-primary btn1">Save and Proceed to Payment</Link>}</li>
                            </ul>
                            <h3>Complete Your Order Through</h3>
                            <PayPalButtons
                            style={{ layout: "vertical" }}
                            createOrder={createOrder}
                            onApprove={onApprove}
                            />
                        </div>
                        <div className='col-lg-4 rightBillingSection'>
                            {currentPackage ? <div className='packageBoxBlack'>
                                <h4>Selected Players service</h4>
                                <h3>{currentPackage.title}</h3>
                                <h5>Price</h5>
                                <p>${currentPackage.price}</p>
                            </div> : null}
                            
                            <div className='grayFeaturedDiv'>
                                <h3>You will get:</h3>
                                {currentPackage && <div dangerouslySetInnerHTML={{__html:currentPackage.description}}></div>}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </PayPalScriptProvider>
        </>
    );
}

export default PaymentMethod;