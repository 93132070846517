import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import LeftPanel from './LeftPanel';
import $ from 'jquery';
import axios from './config/axios';

const userImg = require("./images/user-img.png");


const MyAccountEdit = () => { 
    const [currentUser,setCurrentUser] = useState(null);

    useEffect( () => {
        $(function(){
            $(".side-toggle").click(function() {
                $(".leftPanel").toggleClass("activeLeftPanel");
                $(".side-toggle").toggleClass("active");
            });
        });
        const _currentUser = JSON.parse(localStorage.getItem("currentUser"));
        setCurrentUser({..._currentUser});
    }, []);

    const changeProfileHandler = (event,source) => {
        const {profile} = currentUser;
        profile[source] = event.target.value;
        setCurrentUser({...currentUser,user:{...profile}});
    }

    const updateProfileHandler = () => {
        const {profile} = currentUser;
        delete profile.id;
         axios.post("/editprofile",profile)
         .then(response => {
            alert("Profile updated successfully");
            localStorage.setItem("currentUser",JSON.stringify(currentUser));
         });
    }

    return (
        <>
             <div className='row adminContentBox'>
                <LeftPanel />
                <div className='col-lg-11 rightPanel'>
                <button className="side-toggle" type="button"><span className='toggleSpan'></span></button>
                    <div className='rightpanelNav'>
                        {/* <span className='userImgTotal'><span className='userImg'><img src={userImg} alt='img' /></span><span className='userName'>Hello Rechard!</span></span> */}
                        <span className='userImgTotal'><span className='userName'>Hello Rechard!</span></span>
                    </div>
                    <h1>My Account</h1>
                    <section id="myaccountSec">
                        <div className="row">
                            <div className="col-lg-9">
                                {currentUser && <div className="myaccountlist">
                                    <h3>My Profile</h3>
                                    <ul className='editList row'>
                                        <li className='col-lg-6'><label>First Name</label>
                                        <input type="text" className="form-control" value={currentUser.profile.firstname} onChange={event => changeProfileHandler(event,'firstname')} /></li>
                                        <li className='col-lg-6'><label>Last Name</label><input type="text" className="form-control" value={currentUser.profile.lastname} onChange={event => changeProfileHandler(event,'lastname')} /></li>
                                        <li className='col-lg-12'><label>Full Address</label><input type="text" className="form-control" value={currentUser.profile.fulladdress} onChange={event => changeProfileHandler(event,'fulladdress')} /></li>
                                        <li className='col-lg-6'><label>City</label><input type="text" className="form-control" value={currentUser.profile.city} onChange={event => changeProfileHandler(event,'city')} /></li>
                                        <li className='col-lg-6'><label>State/Province</label><input type="text" className="form-control" value={currentUser.profile.state} onChange={event => changeProfileHandler(event,'state')} /></li>
                                        <li className='col-lg-6'><label>Zipcode</label><input type="text" className="form-control" value={currentUser.profile.zipcode} onChange={event => changeProfileHandler(event,'zipcode')} /></li>
                                        <li className='col-lg-6'><label>Email</label><input type="email" className="form-control" value={currentUser.user.email} disabled /></li>
                                        {/* <li className='col-lg-6'><label>Fax</label><input type="tel" className="form-control" value={currentUser.profile.fax} onChange={event => changeProfileHandler(event,'fax')} /></li> */}
                                        <li className='col-lg-6'><label>Phone</label><input type="tel" className="form-control" value={currentUser.profile.phone} onChange={event => changeProfileHandler(event,'phone')} /></li> 
                                        <li className='col-lg-6'><button type="button" onClick={updateProfileHandler} class="btn btn-primary btn1">Save</button></li> 
                                    </ul>
                                </div>}
                                
                                <div class="myaccountlist">
                                    <h3>My Password</h3>
                                    <ul className='editList row'>
                                        <li className='col-lg-6'><label>Password</label><input type="Password" className="form-control" value='******' /></li>
                                        <li className='col-lg-6'><label>Confirm Password</label><input type="Password" className="form-control" value='******' /></li>
                                        <li className='col-lg-12'><button type="Submit" class="btn btn-primary btn1">Save</button></li> 
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default MyAccountEdit;