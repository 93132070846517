import React from 'react';
const Register = () => { 
    return (
        <>
            <div className='forgotPasswordSection'>
                <div className='container'>
                    <div className='forgotPasswordWhiteSec'>
                        <h3>Register</h3>
                        <ul className='forgotList'>
                            <li><input type="text" className="form-control" placeholder="Enter your Name" /></li>
                            <li><input type="email" className="form-control" placeholder="Enter your email" /></li>
                            <li><input type="password" className="form-control" placeholder="Enter your password" /></li>
                            <li><input type="submit" value="Submit" className='btn' /></li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;