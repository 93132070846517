import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import Home from './Home';
import About from './About';
import Services from './Services';
import Blog from './Blog';
import BlogDetails from './BlogDetails';
import Contact from './Contact';
import ForgotPassword from './ForgotPassword';
import Register from './Register';
import PaymentBilling from './PaymentBilling';
import CustomerInformation from './CustomerInformation';
import PaymentMethod from './PaymentMethod';
import ThankPayment from './ThankPayment';
import Dashboard from './Dashboard';
import PlayersListing from './PlayersListing';
import PlayerDetails from './PlayerDetails';
import PlanPricing from './PlanPricing';
import MyAccount from './MyAccount';
import TermsConditions from './TermsConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Disclaimer from './Disclaimer';
import MyAccountEdit from './MyAccountEdit';

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/BlogDetails/:id" element={<BlogDetails />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Register" element={<Register />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/Disclaimer" element={<Disclaimer />} />
        <Route path="/PaymentBilling" element={<PaymentBilling />} />
        <Route path="/CustomerInformation/:id" element={<CustomerInformation />} />
        <Route path="/PaymentMethod" element={<PaymentMethod />} />
        <Route path="/ThankPayment" element={<ThankPayment />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path="/PlayersListing" element={<PlayersListing />} />
        <Route path="/PlayerDetails/:id" element={<PlayerDetails />} />
        <Route path="/PlanPricing" element={<PlanPricing />} />
        <Route path="/MyAccount" element={<MyAccount />} />
        <Route path="/MyAccountEdit" element={<MyAccountEdit />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
