import React, {useEffect, useState} from 'react';
import { NavLink, Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import './index.css';
import Aos from 'aos';
import axios from './config/axios';
import 'aos/dist/aos.css';
import 'react-modal-video/css/modal-video.min.css';
import ModalVideo from 'react-modal-video'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

const backgroundImage = require("./images/banner-img.jpg");
const humanImg = require("./images/home_man_img.png");
const laptopImg = require("./images/screenshot.jpeg");
const subcribeImg = require("./images/home_subcribe_img.png");
const homeBannerImages = [
    {
        image: require("./images/home-banner-slider-img.png"),
        youtube: 'L61p2uyiMSo'
    },
    {
        image: require("./images/home-banner-slider-img.png"),
        youtube: 'L61p2uyiMSo'
    },
    {
        image: require("./images/home-banner-slider-img.png"),
        youtube: 'L61p2uyiMSo'
    },
    {
        image: require("./images/home-banner-slider-img.png"),
        youtube: 'L61p2uyiMSo'
    },
    {
        image: require("./images/home-banner-slider-img.png"),
        youtube: 'L61p2uyiMSo'
    },
    {
        image: require("./images/home-banner-slider-img.png"),
        youtube: 'L61p2uyiMSo'
    },
]
const options = {
    items: 3,
    margin: 30,
    nav: true,
    rewind: true,
    center: true,
    loop: true,
    responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    
};


const Home = () => { 
    let whyNobleNum = 1;
    const [isOpen, setIsOpen] = useState(false);
    const [currentUser,setCurrentUser] = useState(null);
    const [watchVideoModal, setWatchVideoModal] = useState(false);
    const [aboutSection,setAboutSection] = useState({title:"",subtitle:"",description:""});
    const [supremePackage,setSupremePackage] = useState(null);
    const [otherPackages,setOtherPackages] = useState([]);
    const [whyNoble,setWhyNoble] = useState([]);
    const [benifits, setBenifits] = useState([]);
    const [coach,setCoach] = useState(null);
    const [player,setPlayer] = useState(null);
    const [parent,setParent] = useState(null);
    const [slider,setSlider] = useState([]);
    const [homePageInfo,setHomePageInfo] = useState({});
    const [testimonials,setTestimonials] = useState([]);
    const [currentPackage,setCurrentPackage] = useState(null);
    
    useEffect( () => {
        const _currentPackage = JSON.parse(localStorage.getItem("currentPackage"));
        if(_currentPackage){
            setCurrentPackage({..._currentPackage});
        }
        Aos.init();
        const _currentUser = localStorage.getItem("currentUser");
        if(_currentUser){
           
            setCurrentUser(JSON.parse(_currentUser));
            
        }
        


        //getting about section 
        axios.get("/about")
        .then(response => {
            setAboutSection({...response.data.data[0]});
        });
       
        //getting packages
        axios.get("/package")
        .then(response => {
            const {data} = response;
            const packages = data.data;
            const supremePackages = packages.filter(pack => pack.title.toLowerCase().includes("supreme"))[0];
            console.log("Supreme package",supremePackages);
            //setting up supreme package
            setSupremePackage({...supremePackages});
            //setting up other packages
            const otherPackages = packages.filter(pack => !pack.title.toLowerCase().includes("supreme"));
            setOtherPackages(otherPackages);
        })

        //getting why noble
        axios.get("/whynoble")
        .then(response => {
            const {data} = response.data;
            setWhyNoble(data);

        })

        //getting benifits
        axios.get("/benefit")
        .then(response => {
            setBenifits(response.data.data);
            const coachData = response.data.data.filter(b => b.title.toLowerCase().includes("coach".toLowerCase()));
            setCoach(coachData.length >= 1 ? coachData[0] : null);
            const playerData = response.data.data.filter(b => b.title.toLowerCase().includes("player".toLowerCase()));
            setPlayer(playerData.length >= 1 ? playerData[0] : null);
            const parentData = response.data.data.filter(b => b.title.toLowerCase().includes("parents".toLowerCase()));
            setParent(parentData.length >= 1 ? parentData[0] : null);

        })

        axios.get("/slider")
        .then(response => {
            const {data} = response.data;
            if(data[0]){
                const {title,description} = data[0];
                const info = {
                    title,
                    description
                };
                setHomePageInfo(info);
                setSlider(data);
            }
            
        })

        axios.get("/testimonial")
        .then(response => {
            const {data} = response.data;
            setTestimonials(data);
            console.log("testimonials",data);
        })

      }, []);
    return (
        <>
            <div className='homeBanner' style ={{backgroundImage: "url("+backgroundImage+")"}}>
                <div className='container align-self-center'>
                    <div className='row'>
                        {homePageInfo.title ? <div className='col-md-6 leftHomeBanner' data-aos='fade-right' data-aos-delay="200" data-aos-offset="100">
                            <h2>{homePageInfo.title}</h2>
                            <p dangerouslySetInnerHTML={{__html:homePageInfo.description}}></p>
                            <NavLink to="/Services" className='signupLink'>Services</NavLink>
                            <span className='watchVideoLink' onClick={() => setWatchVideoModal(true)}><span className='playBtn'></span>Watch Video</span>
                        </div> : null}
                        
                        <div className='col-md-6 rightHomeBanner align-self-center'>
                            <div className='homeBannerSlider'>
                            <Carousel showArrows={true}>
                                {slider.map(item =>  <div key={item.id}>
                                            {/* <ModalVideo channel='youtube' isOpen={isOpen} videoId={item.youtube} onClose={() => setIsOpen(false)} /> */}
                                            <img src={item.image} alt="img" />
                                            <div className='overlayDiv' onClick={() => setIsOpen(true)}></div>
                                        </div>)}
                             </Carousel>
                              
                            </div>
                                {slider.map((item , i) => (
                                    <div key={item.id}>
                                        <ModalVideo channel='youtube' isOpen={isOpen} videoId={item.video_link} onClose={() => setIsOpen(false)} />
                                        {/* <img src={item.image} alt="img" /> */}
                                        {/* <div className='overlayDiv' onClick={() => setIsOpen(true)}></div> */}
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className='homeSectionOne'>
                <div className='container-fluid max-width-container'>
                    <div className='row'>
                        <div className='col-lg-6 img-content text-center'><img src={aboutSection.image} alt="img" /></div>
                        <div className='col-lg-6 text-contenrt' data-aos='fade-left' data-aos-delay="200" data-aos-offset="100">
                            <h3>{aboutSection.title}</h3>
                            <h4>{aboutSection.subtitle}</h4>
                            <p dangerouslySetInnerHTML={{__html:aboutSection.description}}></p>
                            <NavLink to="/About" className='btn btn1'>Read More</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            {benifits.length >= 3 ? <div className='homeSectionTwo'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="400" data-aos-offset="300">How Noble Sports Management Benefits:</h3>
                    <div className='row' data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">
                        
                        {player ? <div className='col-lg-4'>
                            <div className='grayDiv'>
                                <div className='imgDiv playerIcon'></div>
                                <h4>{player.title}</h4>
                                <p dangerouslySetInnerHTML={{__html:player.description}}></p>
                            </div>
                        </div> : player}
                       {parent ?  <div className='col-lg-4'>
                            <div className='grayDiv'>
                                <div className='imgDiv parentsIcon'></div>
                                <h4>{parent.title}</h4>
                                <p dangerouslySetInnerHTML={{__html:parent.description}}></p>
                            </div>
                        </div> : parent}
                        {coach ? <div className='col-lg-4'>
                            <div className='grayDiv'>
                                <div className='imgDiv coachesIcon'></div>
                                <h4>{coach.title}</h4>
                                <p dangerouslySetInnerHTML={{__html:coach.description}}></p>
                            </div>
                        </div> : coach}
                        
                    </div>
                </div>
            </div> : null}
            
            <div className='homeSectionthree'>
                <div className='container-fluid max-width-container'>
                    <div className='row'>
                        <div className='col-lg-6 img-content text-center align-self-center'><img style={{height:'350px',width:'350px'}} src={laptopImg} alt="img" /></div>
                        <div className='col-lg-6 text-contenrt' data-aos='fade-left' data-aos-delay="200" data-aos-offset="100">
                            <h3>Why Choose Noble Sports Management</h3>
                            <div class="list-wrap">
                                <div class="list">
                                    {
                                        whyNoble.map(feature =>  <div className='numberList'><span className='numberSpan'>{whyNobleNum++}</span> <span dangerouslySetInnerHTML={{__html:feature.description}}></span></div>)
                                    }
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='homeSectionFour'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">Testimonials</h3>
                    <div className='testiClientSlider' data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">
                        {testimonials.length === 1 &&  <div style={{width:"40%",margin:"0 auto"}} className='grayDiv'>
                                    <div className='topImgDiv'>
                                        <div className='row align-items-center'>
                                            {/* <div className='col-lg-4 imgDiv'><img src={testimonial.image}  alt="img" /></div> */}
                                            <div className='col-lg-12 textDiv'>
                                                <h4>{testimonials[0].name}</h4>
                                                <h5>{testimonials[0].slogan}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bottomTextDiv' dangerouslySetInnerHTML={{__html:testimonials[0].description}}>
                                       
                                    </div>
                                </div>}

                                {testimonials.length === 2 &&  <><div style={{width:"40%",float:"left",margin:"0px 50px"}} className='grayDiv'>
                                    <div className='topImgDiv'>
                                        <div className='row align-items-center'>
                                            {/* <div className='col-lg-4 imgDiv'><img src={testimonial.image}  alt="img" /></div> */}
                                            <div className='col-lg-12 textDiv'>
                                                <h4>{testimonials[0].name}</h4>
                                                <h5>{testimonials[0].slogan}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bottomTextDiv' dangerouslySetInnerHTML={{__html:testimonials[0].description}}>
                                       
                                    </div>
                                </div><div style={{width:"40%",float:"left",margin:"0px 50px"}} className='grayDiv'>
                                    <div className='topImgDiv'>
                                        <div className='row align-items-center'>
                                            {/* <div className='col-lg-4 imgDiv'><img src={testimonial.image}  alt="img" /></div> */}
                                            <div className='col-lg-12 textDiv'>
                                                <h4>{testimonials[1].name}</h4>
                                                <h5>{testimonials[1].slogan}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bottomTextDiv' dangerouslySetInnerHTML={{__html:testimonials[0].description}}>
                                       
                                    </div>
                                </div></>}
                        {testimonials.length > 2 ? <OwlCarousel className="slider-items owl-carousel owl1" options={options}>
                            {testimonials.map(testimonial =>  <div className='item1'>
                                <div className='grayDiv'>
                                    <div className='topImgDiv'>
                                        <div className='row align-items-center'>
                                            {/* <div className='col-lg-4 imgDiv'><img src={testimonial.image}  alt="img" /></div> */}
                                            <div className='col-lg-12 textDiv'>
                                                <h4>{testimonial.name}</h4>
                                                <h5>{testimonial.slogan}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bottomTextDiv' dangerouslySetInnerHTML={{__html:testimonial.description}}>
                                       
                                    </div>
                                </div>
                            </div>)}
                           
                            
                        </OwlCarousel> : null}
                        
                    </div>
                </div>
            </div>
            <div className='homeSectionFive'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">Choose a Subscription To Get Access To Top Players!</h3>
                    {supremePackage && <div className='row align-items-center' data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">
                        <div className='col-lg-6 imgDiv'><img src={supremePackage.image} alt="img" /></div>
                        <div className='col-lg-6 textDiv'>
                           {supremePackage ? <div> <h4>{supremePackage.title}</h4>
                            <p style={{color:"white"}} dangerouslySetInnerHTML={{__html:supremePackage.description}}>
                            </p>
                            <h5>Price</h5>
                            <h6>${supremePackage.price}</h6>
                            <Link to={currentUser && !currentPackage ? '/dashboard' :'/CustomerInformation/'+supremePackage.id} className='btn'>Subscribe</Link></div> : null}
                        </div>
                    </div> }
                    
                </div>
            </div>
            <div className='homeSectionSix'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">Player Service Packages</h3>
                    <div className='container'>
                        <div className='row align-items-center'>
                            {otherPackages[0] && <div style={{margin:"0 auto"}} className='col-lg-6 planOne' data-aos='fade-right' data-aos-delay="200" data-aos-offset="100">
                                {otherPackages[0] ? (<div><h4>{otherPackages[0].title}</h4>
                                <h5>${otherPackages[0].price}</h5>
                                <p dangerouslySetInnerHTML={{__html:otherPackages[0].description}}></p><Link to={currentUser && !currentPackage  ? '/dashboard' : '/CustomerInformation/'+otherPackages[0].id} className='btn'>Subscribe</Link></div>) : null}
                                
                            </div>}
                            
                            {otherPackages[1] &&   <div className='col-lg-6 planTwo' data-aos='fade-left' data-aos-delay="200" data-aos-offset="100">
                            {otherPackages[1] ? (<div><h4>{otherPackages[1].title}</h4>
                                <h5>${otherPackages[1].price}</h5>
                                <p dangerouslySetInnerHTML={{__html:otherPackages[1].description}}></p><Link to={currentUser && !currentPackage  ? '/dashboard' :'/CustomerInformation/'+otherPackages[1].id} className='btn'>Subscribe</Link></div>) : null}
                                
                            </div>}
                          
                            
                        </div>
                    </div>
                </div>
            </div>
            <ModalVideo channel='youtube' isOpen={watchVideoModal} videoId='L61p2uyiMSo' onClose={() => setWatchVideoModal(false)} />
        </>
    );
}

export default Home;