import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import Aos from 'aos';
import axios from './config/axios';
const aboutBannerImg = require("./images/about-banner.jpg");
const aboutImg = require("./images/about-img1.jpg");
const aboutBg2 = require("./images/about-bg2.jpg");
const otherStaffImg1 = require("./images/other-staff-img1.png");
const otherStaffImg2 = require("./images/other-staff-img2.png");
const otherStaffImg3 = require("./images/other-staff-img3.png");
const otherStaffImg4 = require("./images/other-staff-img4.png");
const subcribeImg = require("./images/home_subcribe_img.png");



const About = () => { 
    const [pages,setPages] = useState([]);
    const [aboutSection,setAboutSection] = useState({title:"",subtitle:"",description:""});
    const [staff,setStaff] = useState([]);
    const [supremePackage,setSupremePackage] = useState(null);
    const [otherPackages,setOtherPackages] = useState([]);
    const [currentUser,setCurrentUser] = useState(null);
    const [currentPackage,setCurrentPackage] = useState(null);

    useEffect( () => {
        const _currentPackage = JSON.parse(localStorage.getItem("currentPackage"));
        if(_currentPackage){
            setCurrentPackage({..._currentPackage});
        }

        Aos.init();
        const _currentUser = localStorage.getItem("currentUser");
        if(_currentUser){
           
            setCurrentUser(JSON.parse(_currentUser));
            
        }
       axios.get("/pages")
       .then(response => {
        const {data} = response.data;
        setPages(data);
       });

       axios.get("/about")
       .then(response => {
           setAboutSection({...response.data.data[0]});
           
       });

       axios.get("/staff")
       .then(response => {
            const {data} = response.data;
            setStaff(data);
       })

       axios.get("/package")
        .then(response => {
            const {data} = response;
            const packages = data.data;
            const supremePackages = packages.filter(pack => pack.title.toLowerCase().includes("supreme"))[0];
            //setting up supreme package
            setSupremePackage({...supremePackages});
            //setting up other packages
            const otherPackages = packages.filter(pack => !pack.title.toLowerCase().includes("supreme"));
            setOtherPackages(otherPackages);
        })
       
      }, []);
      
    return (
        <>
            <div className='aboutBanner' style ={{backgroundImage: "url("+aboutBannerImg+")"}} data-aos='fade-in' data-aos-delay="200" data-aos-offset="100">
                <div className='container align-self-center'>
                    <h1>About Us</h1>
                    {pages[1] ? <p dangerouslySetInnerHTML={{__html:pages[1].description}}></p> : null}
                    
                </div>
            </div>
            <div className='headingSection'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">{aboutSection.title}</h3>
                </div>
            </div>
            <div className='aboutSectionOne'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5 img-content text-center' data-aos='fade-right' data-aos-delay="300" data-aos-offset="300"><img src={aboutSection.image} alt="img" /></div>
                        <div className='col-lg-7 text-contenrt' data-aos='fade-left' data-aos-delay="300" data-aos-offset="300">
                            <h3>{aboutSection.subtitle}</h3>
                            <p dangerouslySetInnerHTML={{__html:aboutSection.description}}></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='aboutSectionTwo' style ={{backgroundImage: "url("+aboutBg2+")"}}>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">Partnerships</h3>
                    <ul className='staffList row'>
                        {staff.map(person =>  <li key={person.id} className='col-lg-6' data-aos='fade-up' data-aos-delay="200" data-aos-offset="200">
                            <div className='row'>
                                <div className='col-lg-5 imgContent'><img src={person.image} alt="img" /></div>
                                <div className='col-lg-7 textContent'>
                                    <h4>{person.title}</h4>
                                    <p dangerouslySetInnerHTML={{__html:person.description}}></p>
                                </div>
                            </div>
                        </li>)}
                       
                    </ul>
                </div>
            </div>
            <div className='homeSectionFive'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="300" data-aos-offset="300">Choose a Subscription To Get Access To Top Players!</h3>
                    <div className='row align-items-center' data-aos='fade-up' data-aos-delay="400" data-aos-offset="300">
                        <div className='col-lg-6 imgDiv'>{supremePackage && <img src={supremePackage.image} alt="img" />}</div>
                        <div className='col-lg-6 textDiv'>
                            {supremePackage ? <h4>{supremePackage.title}</h4> : null}
                            {supremePackage ? <div dangerouslySetInnerHTML={{__html:supremePackage.description}}></div> : null}
                            {
                                supremePackage ? <div><h5>Price</h5>
                                <h6>$ {supremePackage.price}</h6>        <Link to={currentUser && !currentPackage ? '/dashboard' : '/CustomerInformation/' + supremePackage.id} className='btn'>Subscribe</Link></div>

                                :null
                            }
                            
                    
                        </div>
                    </div>
                </div>
            </div>
            <div className='homeSectionSix'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="400" data-aos-offset="300">Player Service Packages</h3>
                    <div className='container'>
                        <div className='row align-items-center'>
                           {otherPackages[0] ?   <div style={{margin:"0 auto"}} className='col-lg-6 planOne' data-aos='fade-right' data-aos-delay="400" data-aos-offset="400">
                                <h4>{otherPackages[0].title}</h4>
                                <h5>${otherPackages[0].price}</h5>
                                <div dangerouslySetInnerHTML={{__html:otherPackages[0].description}}></div>
                                <Link to={currentUser && !currentPackage ? '/dashboard' : '/CustomerInformation/' + otherPackages[0].id} className='btn'>Subscribe</Link>
                            </div>  : null}
                            {otherPackages[1] ? <div className='col-lg-6 planTwo' data-aos='fade-left' data-aos-delay="400" data-aos-offset="400">
                                <h4>{otherPackages[1].title}</h4>
                                <h5>${otherPackages[1].price}</h5>
                                <div style={{color:"white"}} dangerouslySetInnerHTML={{__html:otherPackages[1].description}}></div>
                                <Link to={ currentUser && !currentPackage ? '/dashboard' :'/CustomerInformation/' + otherPackages[1].id} className='btn'>Subscribe</Link>
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default About; 
