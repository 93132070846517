import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import Aos from 'aos';
import axios from './config/axios';

const servicestBannerImg = require("./images/services-banner.jpg");
const subcribeImg = require("./images/home_subcribe_img.png");
const aboutBannerImg = require("./images/about-banner.jpg");

const Services = () => { 
    const [pages,setPages] = useState([]);
    const [supremePackage,setSupremePackage] = useState(null);
    const [otherPackages,setOtherPackages] = useState([]);
    const [serviceInfo,setServiceInfo] = useState(null);
    const [currentUser,setCurrentUser] = useState(null);
    const [currentPackage,setCurrentPackage] = useState(null);

    useEffect( () => {
        const _currentPackage = JSON.parse(localStorage.getItem("currentPackage"));
        if(_currentPackage){
            setCurrentPackage({..._currentPackage});
        }
        Aos.init();
        const _currentUser = localStorage.getItem("currentUser");
        if(_currentUser){
           
            setCurrentUser(JSON.parse(_currentUser));
            
        }
        axios.get("/pages")
        .then(response => {
         const {data} = response.data;
         setPages(data);
        });

         //getting service info
         axios.get("/services")
         .then(response => {
             const {data} = response.data;
             setServiceInfo(data[0]);
         })

        axios.get("/package")
        .then(response => {
            const {data} = response;
            const packages = data.data;
            const supremePackages = packages.filter(pack => pack.title.toLowerCase().includes("supreme"))[0];
            //setting up supreme package
            setSupremePackage({...supremePackages});
            //setting up other packages
            const otherPackages = packages.filter(pack => !pack.title.toLowerCase().includes("supreme"));
            setOtherPackages(otherPackages);
        })
      }, []);
    return (
        <>
            <div className='aboutBanner' style ={{backgroundImage: "url("+aboutBannerImg+")"}} data-aos='fade-in' data-aos-delay="200" data-aos-offset="100">
                <div className='container align-self-center'>
                    <h1>Services</h1>
                    {pages[0] ? <p dangerouslySetInnerHTML={{__html:pages[0].description}}></p>  : null}
                </div>
            </div>
            <div className='homeSectionFive servicesSectionFive'>
                <div className='container'>
                    {serviceInfo ?  <div>
                    <h3 data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">{serviceInfo.title}</h3>
                    <p data-aos='fade-up' data-aos-delay="200" data-aos-offset="100" dangerouslySetInnerHTML={{__html:serviceInfo.description}}></p>
                    </div> : null}
                   
                    <div className='row align-items-center' data-aos='fade-up' data-aos-delay="200" data-aos-offset="100">
                       
                       {supremePackage ?   <><div className='col-lg-6 imgDiv'><img src={supremePackage.image} alt="img" /></div><div className='col-lg-6 textDiv'>
                            <h4>{supremePackage.title}</h4>
                            <div dangerouslySetInnerHTML={{__html:supremePackage.description}}></div>
                            <h5>Price</h5>
                            <h6>${supremePackage.price}</h6>
                            <Link to={currentUser && !currentPackage ? '/dashboard' :'/CustomerInformation/' + supremePackage.id} className='btn'>Subscribe</Link>
                        </div></> : null}
                    </div>
                </div>
            </div>
            <div className='homeSectionSix'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="400" data-aos-offset="300">Player Service Packages</h3>
                    <div className='container'>
                        <div className='row align-items-center'>
                            {otherPackages[0] ? <div style={{margin:"0 auto"}} className='col-lg-6 planOne' data-aos='fade-right' data-aos-delay="400" data-aos-offset="400">
                                <h4>{otherPackages[0].title}</h4>
                                <h5>${otherPackages[0].price}</h5>
                                <div dangerouslySetInnerHTML={{__html:otherPackages[0].description}}></div>
                                <Link to={currentUser && !currentPackage ? '/dashboard' :'/CustomerInformation/' + otherPackages[0].id} className='btn'>Subscribe</Link>
                            </div>  :null}
                            {otherPackages[1] ? <div className='col-lg-6 planTwo' data-aos='fade-left' data-aos-delay="400" data-aos-offset="400">
                                <h4>{otherPackages[1].title}</h4>
                                <h5>${otherPackages[1].price}</h5>
                                <div style={{color:"white"}} dangerouslySetInnerHTML={{__html:otherPackages[1].description}}></div>
                                <Link to={currentUser && !currentPackage ? '/dashboard' :'/CustomerInformation/' + otherPackages[0].id} className='btn'>Subscribe</Link>
                            </div>  : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Services;