import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const cardImage = require("./images/card-img.png");
const paypalImage = require("./images/paypal-img.png");



const PaymentBilling = () => { 
    return (
        <>
            <div className='paymentBillingSection'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 leftBillingSection'>
                            <h3>Billing Address <span>*</span></h3>
                            <ul className='billingList row'>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="First Name" /></li>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="Last Name" /></li>
                                <li className='col-lg-12'><input type="text" className="form-control" placeholder="Full Address" /></li>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="City" /></li>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="State/Province" /></li>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="Zipcode" /></li>
                            </ul>
                            <h3>Credit Card <span>*</span></h3>
                            <h4>Pay with card: <span className='cardImg'><img src={cardImage} alt="img" /></span></h4>
                            <ul className='billingList row'>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="Card Number" /></li>
                                <li className='col-lg-6'><input type="text" className="form-control" placeholder="Name on Card" /></li>
                                <li className='col-lg-4'>
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected>Exp mm</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </li>
                                <li className='col-lg-4'>
                                    <select className="form-select" aria-label="Default select example">
                                        <option selected>Exp yyyy</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </li>
                                <li className='col-lg-4'><input type="text" className="form-control" placeholder="Cvv Number" /></li>
                                <li className='col-lg-5'><button type="Submit" class="btn btn-primary btn1">Save and Proceed to Payment</button></li>
                            </ul>
                            <div className='orDiv'><span className='borderLeftSpan'></span><span className='orSpan'>Or</span><span className='borderRightSpan'></span></div>
                            <h4>Pay with Paypal: <span className='paypalImg'><img src={paypalImage} alt="img" /></span></h4>
                            <p className='paypalIcon'><span className='graySpan'>Pay with PaypPal. As a member, your eligible purchases are covered by <span className='payPalLink'>PayPal Purchase Protection.</span></span> 
                            <OverlayTrigger key={'top'} placement={'top'} overlay={<Tooltip id={`tooltip-top`}>Tooltip on <strong>top</strong></Tooltip>}>
                                <Button variant="secondary" className='tooltipBtn'></Button>
                            </OverlayTrigger>
                            </p>
                            <button type="Submit" class="btn btn-primary payPalBtn">Pay with PayPal</button>
                        </div>
                        <div className='col-lg-4 rightBillingSection'>
                            <div className='packageBox'>
                                <h4>Selected Players service</h4>
                                <h3>Package 1</h3>
                                <h5>Price</h5>
                                <p>$250.00</p>
                            </div>
                            <div className='grayFeaturedDiv'>
                                <h3>You will get:</h3>
                                <ul className='grayFeatureList'>
                                    <li>Evaluate your goals/skills </li>
                                    <li>Sharpen interview Skills</li>
                                    <li>Target appropriate college</li>
                                    <li>Determine Eligibility/Academic Requirements</li>
                                    <li>Provide Continuous support</li>
                                    <li>Market your strengths</li>
                                    <li>Guide you through official and unofficial visits</li>
                                    <li>Develop introduction email</li>
                                    <li>Social media review and advising</li>
                                    <li>Advice regarding NCAA Rules/Regulations</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentBilling;