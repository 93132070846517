import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import LeftPanel from './LeftPanel';
import $ from 'jquery';

const userImg = require("./images/user-img.png");


const MyAccount = () => { 
    const [currentUser,setCurrentUser] = useState(null);
    useEffect( () => {
        const _currentUser =  JSON.parse(localStorage.getItem("currentUser"));
        console.log(_currentUser);
        setCurrentUser({..._currentUser});
        $(function(){
            $(".side-toggle").click(function() {
                $(".leftPanel").toggleClass("activeLeftPanel");
                $(".side-toggle").toggleClass("active");
            });
        });
    }, []);
    return (
        <>
             <div className='row adminContentBox'>
                <LeftPanel />
                <div className='col-lg-11 rightPanel'>
                <button className="side-toggle" type="button"><span className='toggleSpan'></span></button>
                    <div className='rightpanelNav'>
                        {/* <span className='userImgTotal'><span className='userImg'><img src={userImg} alt='img' /></span><span className='userName'>Hello Rechard!</span></span> */}
                        {currentUser &&  <span className='userImgTotal'><span className='userName'>Hello {currentUser.user.first_name}!</span></span>}
                    </div>
                    <h1>My Account</h1>
                    <section id="myaccountSec">
                        <div className="row">
                            <div className="col-lg-9">
                                <div className="myaccountlist">
                                    <h3>My Profile</h3>
                                    {currentUser &&  <ul>
                                        <li>
                                            <label>Name:</label>
                                            {currentUser.user.first_name + " " + currentUser.user.last_name}
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                        <li>
                                            <label>Address:</label>
                                                {currentUser.profile.fulladdress}
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                        <li>
                                            <label>City:</label>
                                            {currentUser.profile.city} 
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                        <li>
                                            <label>State/Province:</label>
                                            {currentUser.profile.state} 
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                        <li>
                                            <label>Zip Code:</label>
                                            {currentUser.profile.zipcode} 
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                        <li>
                                            <label>Email:</label>
                                            {currentUser.user.email} 
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                        <li>
                                            <label>Phone:</label>
                                            {currentUser.user.mobile_number} 
                                            <Link to='/MyAccountEdit' className="editBtn">Edit</Link>
                                        </li>
                                       
                                    </ul>}
                                   
                                </div>
                                <div class="myaccountlist">
                                    <h3>My Password</h3>
                                    <ul>
                                        <li>
                                            <label>Password:</label>
                                            .............
                                            <Link to='/MyAccountEdit' class="ChangeBtn">Change</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default MyAccount;