import React, {useEffect, useState} from 'react';
import axios from './config/axios';
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import LeftPanel from './LeftPanel';
import $ from 'jquery';
import { UserData } from './Chart';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJs } from 'chart.js/auto'

const userImg = require("./images/user-img.png");
const barchatImg = require("./images/barchat-img.jpg");
const options = {
    items: 3,
    margin: 30,
    nav: true,
    loop: true,
    dots: false,
    responsive: {
        0: {
          items: 1
        },
        600: {
          items: 3
        },
        1000: {
          items: 4
        }
      }
};

var barOptions = {
    responsive: true,
    plugins: {
        tooltip: {
          enabled: false
        },
    },
    scales: {
        x: {
            grid: {
                display: false
            },
            ticks: {
              color: '#525252',
              font: {
                size: 16,
                family: "'Inter', sans-serif",
                weight: 400
              }
            }
        },
        y: {
            grid: {
                display: false
            },
            ticks: {
              display: false,
              color: '#525252',
              font: {
                size: 16,
                family: "'Inter', sans-serif",
                weight: 400
              }
            }
        },
      }
}

//alert(JSON.stringify( UserData))
const Dashboard = () => { 
    useEffect( () => {
        $(function(){
            $(".side-toggle").click(function() {
                $(".leftPanel").toggleClass("activeLeftPanel");
                $(".side-toggle").toggleClass("active");
            });
        });

        const _currentUser =  JSON.parse(localStorage.getItem("currentUser"));
        console.log(_currentUser);
        setCurrentUser({..._currentUser});
        axios.post("/purchasehistory",{user:_currentUser.user.id})
        .then(response => {
            const {data} = response.data;
            setSelectedSubscription({...data[0]});
            console.log(selectedSubscription);
        })

    }, []);
    const [selectedSubscription,setSelectedSubscription] = useState(null);
    const [currentUser,setCurrentUser] = useState(null);
    const [userData, setUserData] = useState({
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
            color: "#F00",
            label: "Users Gained",
            data: UserData.map((data) => data.userGain),
            backgroundColor: UserData.map((data) => data.color),
            borderRadius: 10,
            type: 'bar',
            showTooltips: false,
        },
        // {
        //     labels: "Users Gained",
        //     data: UserData.map((data) => data.userGain),
        //     backgroundColor: UserData.map((data) => data.color),
        //     borderRadius: 10,
        //     type: 'line',
        // }
    ]
    })
    return (
        <>
            <div className='row adminContentBox'>
                <LeftPanel />
                <div className='col-lg-11 rightPanel'>
                    <button className="side-toggle" type="button"><span className='toggleSpan'></span></button>
                    <div className='rightpanelNav'>
                        {/* <span className='userImgTotal'><span className='userImg'><img src={userImg} alt='img' /></span><span className='userName'>Hello Rechard!</span></span> */}
                        {currentUser ? <span className='userImgTotal'><span className='userName'>Hello {currentUser.user.first_name}!</span></span> : null}
                        
                    </div>
                    {/* <h1>Top Players</h1> */}
                    <div className='dashboardSlider' style={{display:'none'}}>
                        <OwlCarousel className="slider-items owl-carousel owl1" options={options}>
                            <div className='item'>
                                <div class="sliderBox">
                                    {/* <div class="sliderBox_img"></div> */}
                                    <h5>Jim Katter</h5>
                                    <p><span>205-391-2306</span> | <span>AL, USA</span> | <span>Shelton State</span></p>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="sliderBox">
                                    {/* <div class="sliderBox_img"></div> */}
                                    <h5>Jim Katter</h5>
                                    <p><span>205-391-2306</span> | <span>AL, USA</span> | <span>Shelton State</span></p>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="sliderBox">
                                    {/* <div class="sliderBox_img"></div> */}
                                    <h5>Jim Katter</h5>
                                    <p><span>205-391-2306</span> | <span>AL, USA</span> | <span>Shelton State</span></p>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="sliderBox">
                                    {/* <div class="sliderBox_img"></div> */}
                                    <h5>Jim Katter</h5>
                                    <p><span>205-391-2306</span> | <span>AL, USA</span> | <span>Shelton State</span></p>
                                </div>
                            </div>
                            <div className='item'>
                                <div class="sliderBox">
                                    {/* <div class="sliderBox_img"></div> */}
                                    <h5>Jim Katter</h5>
                                    <p><span>205-391-2306</span> | <span>AL, USA</span> | <span>Shelton State</span></p>
                                </div>
                            </div>
                        </OwlCarousel>
                    </div>
                    <div className='row'>
                        <div className='col-lg-8' style={{display:'none'}}>
                            <div className='leftPerformanceSection'>
                                <div className='row'>
                                    <div className='col-lg-8'>
                                        <h3>Players Performance</h3>
                                    </div>
                                    <div className='col-lg-4'>
                                        <select className="form-select" aria-label="Default select example">
                                            <option selected>Last Month</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                        <span className='pointText'>+12%</span>
                                    </div>
                                </div>
                                <div className='bottomPerformanceSec'>
                                    {/* <img src={barchatImg} alt="img" /> */}
                                    <Bar data={userData} options={barOptions} id='chart' />
                                </div>
                            </div>
                        </div>
                       {selectedSubscription ?  <div className='col-lg-10'>
                            <div className='packageBoxBlack' style={{display:'none'}}>
                                <h4>Selected Subcription</h4>
                                <h3>{selectedSubscription.name}</h3>
                                <h5>Price</h5>
                                <p>${selectedSubscription.price}</p><br/>
                                <h4>Purchase Date</h4>
                                <p>{selectedSubscription.start_date}</p>
                            </div>
                            {currentUser && parseInt(currentUser.user.role_id) === 4 && <div id="thnaksSection"><div className="btngrp">
          <a href="#" className="btn1">Google Form</a>
          <a href="#" className="btn2">Calendar</a>
          
          </div></div>}
                        </div> : null}
                        {currentUser && parseInt(currentUser.user.role_id) !== 4 && <div id="thnaksSection" style={{padding:0,margin:0,textAlign:'center'}}><div style={{marginTop:230}} className="btngrp">
          <a href="https://drive.google.com/drive/folders/1Ok3sPtYkaJXscFSNmotsG_NkRBE1ks7C?usp=sharing" style={{padding:10,lineHeight:2}} className="btn1">She Is Shining</a>
          <a href="https://drive.google.com/drive/folders/1y8O4rgUxRoEvxQZ1IP5Dmsm9dPC3Q8YG?usp=sharing" style={{padding:10,lineHeight:2,width:300}} className="btn1">Diamonds in the Rough </a>
          <a href="https://drive.google.com/drive/folders/1Ok3sPtYkaJXscFSNmotsG_NkRBE1ks7C?usp=sharing" style={{padding:10,lineHeight:2}} className="btn1">Baller Alert</a>
          <a href="https://drive.google.com/drive/folders/1o3HEmi6YIPFG8y4IzVtJxFe5bvXywTju?usp=sharing" style={{padding:10,lineHeight:2}} className="btn1">Fresh Faces</a>
         
          
          </div></div>}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;