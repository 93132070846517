import React, {useState,useEffect} from 'react';
import { NavLink, Link } from "react-router-dom";
import axios from './config/axios';

const footerLogo = require("./images/footer-logo.png");
const footerTwitterIcon = require("./images/footer-twitter-icon.png");
const footerFacebookIcon = require("./images/footer-facebook-icon.png");
const footerInstagramIcon = require("./images/footer-instgram-icon.png");

const Footer = () => {
    const [settings,setSettings] = useState(null);
    useEffect( () => {
        
        axios.get("/setting")
        .then(response => {
            const {data} = response.data;
            console.log(data);
            setSettings(data[0]);
        })
    }, []);
    return (
        <>
            <div className='footer'>
                <div className='footerTop'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 footerItem footerItem1'>
                                <div className='footer-logo'><NavLink to='/'><img src={footerLogo} alt="img" /></NavLink></div>
                                <h3>Contact Us</h3>
                                {settings &&  <ul className='footerQuickLink'>
                                    <li><a href={"tel:" + settings.phone}><i class="fa fa-phone" aria-hidden="true"></i> {settings.phone}</a></li>
                                    <li><a href={"mailto:" + settings.email}><i class="fa fa-envelope" aria-hidden="true"></i>{settings.email}</a></li>
                                </ul>}
                            </div>
                            <div className='col-lg-4 footerItem footerItem2'>
                                <h3>Follow us on:</h3>
                                <ul className='footerSocialLink'>
                                    {settings && settings.twitter && <li><a href={settings.twitter} target="_blank"><span className='imgSpan'><img src={footerTwitterIcon} alt="img" /></span> Twitter</a></li>}
                                    
                                    
                                    {settings && settings.instagram && <li><a href={settings.instagram} target="_blank"><span className='imgSpan'><img src={footerInstagramIcon} alt="img" /></span> Instagram</a></li>}
                                </ul>
                            </div>
                            <div className='col-lg-4 footerItem footerItem3'>
                                <ul className='footerMenu'>
                                    <li><NavLink to="/about">About us</NavLink></li>
                                    <li><NavLink to="/Services">Services</NavLink></li>
                                    <li><NavLink to="/Blog">Blog</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='footerBottom'>
                    <p>Copyright Notice l <Link to='/TermsConditions'>Terms & Conditions</Link> l <Link to='/PrivacyPolicy'>Privacy Policy</Link> l <Link to='/Disclaimer'>Disclaimer</Link></p>
                </div>
            </div>
        </>
    );
}

export default Footer;