import React, {useEffect,useState} from 'react';
import StarRatings from 'react-star-ratings';
import { Link, useParams } from "react-router-dom";
import axios from './config/axios'
import LeftPanel from './LeftPanel';
import $ from 'jquery';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Modal from 'react-bootstrap/Modal';

const userImg = require("./images/no-image-800x800.jpg");
const userimg2 = require("./images/userimg2.png");
const ratingImg = require("./images/rating.png");
const twitterImg = require("./images/twitter-icon.png");
const fbImg = require("./images/fb-icon.png");
const igImg = require("./images/ig-icon.png");
const coachImg = require("./images/coach.png");
const playIcon = require("./images/play-button.png");
const speakerIcon = require("./images/speeaker.webp")


const PlayerDetails = () => { 
  const params = useParams();
  const [audio,setAudio] = useState(null);
  const [player,setPlayer] = useState(null);
  const [show,setShow] = useState(false);
  const [audioShow, setaudioShow] = useState(false);
  const audioModalClose = () => setaudioShow(false);
  const audioModalShow = () => setaudioShow(true);

  const executeAudio = (event,audioLink) => {
    event.preventDefault();
    setAudio(audioLink);
    setaudioShow(true);
}


    useEffect( () => {
        $(function(){
            $(".side-toggle").click(function() {
                $(".leftPanel").toggleClass("activeLeftPanel");
                $(".side-toggle").toggleClass("active");
            });
        });
        
        const playerId = params.id;

        axios.get("/player")
        .then(response => {
          console.log(playerId);
          const currentPlayer = response.data.data.filter(player => player.id == playerId)[0];
          setPlayer({...currentPlayer});
          console.log(currentPlayer);
        })


    }, []);
    return (
        <>
             <div className='row adminContentBox'>
                <LeftPanel />
                {player ? <div className='col-lg-11 rightPanel'>
                    <button className="side-toggle" type="button"><span className='toggleSpan'></span></button>
                    <section id="myaccountSec">
                    <div className="plyername"> 
                    {player.image &&  <div className='' style={{display:'block',margin:0}}>
                        <span className='userImgTotal'><span className=''><img style={{maxWidth:100,borderRadius:1}} src={player.image} alt='img' /></span> </span>
                    </div>}
                      {/* <span><img src={userimg2} alt='img' /></span> */}
                      <h4>{player.first_name} {player.last_name}</h4>
                      <p><em>National Rank: #{player.national_rank} </em> | <em>Texas Rank: #{player.texas_rank}</em></p>
                    </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="pdList">
                            <ul>
                              <li>
                                <label>Rating:</label>
                                <div className='rightInputContent'>
                                  <StarRatings
                                    rating={player.rating ? parseFloat(player.rating) : 0}
                                    numberOfStars={5}
                                    starRatedColor="#F4C01E"
                                    starDimension="25px"
                                    starSpacing="2px"
                                  />
                                  
                                  {player.rating ? parseFloat(player.rating) : 0} out of 5
                                </div>
                              </li>
                              <li>
                                <label>Height</label>
                                {player.height}
                              </li>
                              <li>
                                <label>Class:</label>
                                {player.class}
                              </li>
                              <li>
                                <label>Signed:</label>
                                {player.signed}
                              </li>
                              <li>
                                <label>Positions:</label>
                                {player.position}
                              </li>
                              <li>
                                <label>Address:</label>
                                {player.city} {player.state}
                              </li>
                              <li>
                                <label>Phone Number: </label>
                                {player.parent_number}
                              </li>
                              <li>
                                <label>High School:</label>
                                {player.high_school}
                              </li>
                              <li>
                                <label>Club Team:</label>
                                {player.club_team}
                              </li>
                              <li>
                                <label>Social Media:</label>
                                {player.twitter && <a target="_blank" href={player.twitter}> <img src={twitterImg} className='inlineOne' alt='lmg' /></a> }
                                {player.facebook && <a target="_blank" href={player.facebook}><img src={fbImg} className='inlineOne' alt='lmg' /></a>}
                                {player.instagram &&  <a target="_blank" href={player.instagram}><img src={igImg} className='inlineOne' alt='lmg' /></a>}
                                {player.youtube && <a target="_blank" href={player.youtube}><img src={playIcon} alt="img" className='inlineOne playIcon' /></a>}

                                {player.audio_link && <a href={player.audio_link} target="_blank"><img src={speakerIcon} alt="img" className='inlineOne playIcon'/></a>}
                               
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-lg-6"> 
                          <div className="evaluationBox">
                            <h5>Evaluation </h5>
                            <div style={{width:'100%',maxHeight:450,wordWrap: 'break-word',padding:10}} dangerouslySetInnerHTML={{__html:player.evaluation}}>
                            
                            </div>
                          </div>
                          <div className="evaluationBox">
                            <h5>Coach Details</h5>
                            <div className="coachInfo"> 
                            <h4>Club Coach</h4>
                              {/* <span className='inlineTwo'>
                                <span className="coach-avatar"><img src={coachImg} alt='lmg' /></span>
                                <span>
                                  <h3>Joey Simmons</h3>
                                  <h6>joey@premierbasketball.com</h6>
                                </span> 
                              </span>  */}
                              <ul>
                                <li>
                                  <label>Name:</label>
                                  
                                  {player.club_coach}
                                </li>
                                <li>
                                  <label>Email:</label>
                                  {player.club_coach_email}
                                </li>
                               <li>
                                  <label>Phone:</label>
                                  {player.club_coach_phone}
                                </li>
                               
                                {/* <li>
                                  <label>Phone Number:</label>
                                </li> */}
                              </ul>
                              <h4>High School Coach</h4>
                              {/* <span className='inlineTwo'>
                                <span className="coach-avatar"><img src={coachImg} alt='lmg' /></span>
                                <span>
                                  <h3>Joey Simmons</h3>
                                  <h6>joey@premierbasketball.com</h6>
                                </span> 
                              </span>  */}
                              <ul>
                                <li>
                                  <label>Name:</label>
                                  
                                  {player.coach_name}
                                 
                                </li>
                                <li>
                                  <label>Email:</label>
                                  {player.coach_email}
                                 
                                </li>
                                <li>
                                  <label>Phone:</label>
                                  {player.high_school_coach_phone}
                                 
                                </li>
                                
                                {/* <li>
                                  <label>Phone Number:</label>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                </div> : null}
                
            </div>
            
            <Modal show={audioShow} onHide={audioModalClose} className='audioModal'>
                <Modal.Header closeButton>
                    <Modal.Title>Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AudioPlayer autoPlay src={audio} onPlay={e => console.log("onPlay")} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PlayerDetails;