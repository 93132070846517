import React, {useState,useEffect} from 'react';
import axios from './config/axios';
import Aos from 'aos';

const Contact = () => { 
    const [contactInfo,setContactInfo] = useState({name:'',email:'',comment:'',nsm:'PLAYERS SERVICES',phone:''});
    const [submitting,setSubmitting] = useState(null);
    const [settings,setSettings] = useState(null);
    useEffect( () => {
        Aos.init();
        axios.get("/setting")
        .then(response => {
            const {data} = response.data;
            setSettings(data[0]);
        })
    }, []);

    const changeHandler = (event,source) => {
        const info = {...contactInfo};
        info[source] = event.target.value;
        setContactInfo({...info});
        
    }

    const submitHandler = () => {
        setSubmitting(true);
        axios.post("/create-contact",contactInfo)
        .then(response => {
            window.alert("Your response submitted successfully");
            setSubmitting(null);
            setContactInfo({name:'',email:'',comment:'',nsm:'',phone:''});
        })

    }


    return (
        <>
          <div className='contactContent'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8 leftContactContent'>
                        <h4>We’d Love to Help!</h4>
                        <h3>JUST SAY HELLO.</h3>
                        <ul className='cotactList row'>
                            <li className='col-lg-6'><input onChange={event => changeHandler(event,'name')} type="text" value={contactInfo.name} className="form-control" placeholder="Your Name" /></li>
                            <li className='col-lg-6'><input type="email" onChange={event => changeHandler(event,'email')} value={contactInfo.email} className="form-control"  placeholder="Your Email" /></li>
                            <li className='col-lg-6'><input type="tel" onChange={event => changeHandler(event,'phone')} value={contactInfo.phone} className="form-control" placeholder="Your Phone" /></li>
                            <li className='col-lg-6'>
                                <select  onChange={event => changeHandler(event,'nsm')} className="form-select" aria-label="Default select example">
                                    <option value="PLAYERS SERVICES" selected>PLAYERS SERVICES</option>
                                    <option value="NSM SCOUTING SERVICES">NSM SCOUTING SERVICES</option>
                                </select>
                            </li>
                            <li className='col-lg-12'>
                                <textarea class="form-control textarea-control" rows="3" onChange={event => changeHandler(event,'comment')} placeholder="Comment">{contactInfo.comment}</textarea>
                            </li>
                            <li className='col-lg-12'>
                                <button type="button" disabled={submitting ? true : false} onClick={submitHandler} class="btn btn-primary">{!submitting ? 'Submit' : 'Please Wait...'}</button>
                            </li>
                        </ul>
                    </div>
                    <div className='col-lg-4 rightContactContent'>
                        <iframe className='gMap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3265.1500368941283!2d-106.66528458478543!3d35.07798377042579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87220cc2bd8d53cf%3A0x7309535dfa677789!2s903%2010th%20St%20SW%2C%20Albuquerque%2C%20NM%2087102%2C%20USA!5e0!3m2!1sen!2sin!4v1657549754644!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        {settings && <ul className='quickList'>
                            <li className='address'><span dangerouslySetInnerHTML={{__html:settings.address}}></span></li>
                            <li className='telephone'><a href={"tel:" + settings.phone}>{settings.phone}</a></li>
                            <li className='email'><a href={"mailto:" + settings.email}>{settings.email}</a></li>
                        </ul>}
                        
                    </div>
                </div>
            </div>
          </div>
        </>
    );
}

export default Contact;