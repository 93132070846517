import React, {useState,useEffect} from 'react';
import { Link, useParams  } from "react-router-dom";
import axios from './config/axios'

const CustomerInformation = (props) => {
    const params = useParams();
    const [newUser,setNewUser] = useState({
        first_name:'',
        lastname:'',
        fulladdress:'',
        city:'',
        state:'',
        zipcode:'',
        email:'',
        phone:'',
        school_affiliation:'',
        title:'',
        password:'',
        cpassword:'',
        role_id:3
    }) 
    const [loading,setLoading] = useState(null);
    const [currentPackage,setCurrentPackage] = useState(null);
    const  [roleId,setRoleId] = useState(4); 
    useEffect(() => {

        axios.get("/package")
        .then(response => {
            const {data} = response;
            const packages = data.data;
            
            const p = packages.filter(pack => pack.id == params.id)[0];
            setCurrentPackage({...p});
            const otherInfo = {};
            if(p.title.toLowerCase().includes("supreme")){
                otherInfo.role_id = 3;
                
            }else{
                otherInfo.role_id = 4;
            }

            setRoleId(otherInfo.role_id);
            otherInfo.amount = p.price;
            localStorage.setItem("otherinfo",JSON.stringify(otherInfo));
            localStorage.setItem("currentPackage",JSON.stringify(p));

        });

    },[])



    const changeHandler = (event,source) => {
        const user = {...newUser};
        user[source] = event.target.value;
        setNewUser({...user});///PaymentMethod
    }

    const submitHandler = (event) => {
        event.preventDefault();
        const user = {...newUser,role_id:roleId};
        if(user.password.length <= 8 || (user.password !== user.cpassword) || user.email.length <= 2){
            alert("Password or confirm password does not match");
        }else{
           setLoading("Please wait.....")
           axios.post("/register",{...user})
            .then(response => {
                setLoading(null)
                const {user,token} = response.data;
                const info = {user,token};
                localStorage.setItem("currentUser",JSON.stringify(info));
                window.location="/PaymentMethod";
               
            }).catch(error => {
                setLoading(null)
                console.log(error);
                alert(error.response.data.errors);
            })
        }
    }

    return (
        <>
            <div className='paymentBillingSection'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-8 leftBillingSection'>
                            <h1>Billing Address</h1>
                            <ul className='billingList row'>
                                <li className='col-lg-6'><input type="text" value={newUser.first_name} onChange={event => changeHandler(event,'first_name')} className="form-control" placeholder="First Name" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.lastname} onChange={event => changeHandler(event,'lastname')} className="form-control" placeholder="Last Name" /></li>
                                <li className='col-lg-12'><input type="text" value={newUser.fulladdress} onChange={event => changeHandler(event,'fulladdress')} className="form-control" placeholder="Full Address" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.city} onChange={event => changeHandler(event,'city')} className="form-control" placeholder="City" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.state} onChange={event => changeHandler(event,'state')} className="form-control" placeholder="State/Province" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.zipcode} onChange={event => changeHandler(event,'zipcode')} className="form-control" placeholder="Zipcode" /></li>
                                <li className='col-lg-6'><input type="email" value={newUser.email} onChange={event => changeHandler(event,'email')} className="form-control" placeholder="Email Address" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.phone} onChange={event => changeHandler(event,'phone')} className="form-control" placeholder="Phone Number" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.school_affiliation} onChange={event => changeHandler(event,'school_affiliation')} className="form-control" placeholder="Name of School" /></li>
                                <li className='col-lg-6'><input type="text" value={newUser.title} onChange={event => changeHandler(event,'title')} className="form-control" placeholder="Coach/Player/Parent" /></li>
                            </ul>
                            <h3>Set Password</h3>
                            <p>Password must be at least 9 characters long</p>
                            <ul className='billingList row'>
                                <li className='col-lg-6'><input type="password" value={newUser.password} onChange={event => changeHandler(event,'password')} className="form-control" placeholder="Password" /></li>
                                <li className='col-lg-6'><input type="password" value={newUser.cpassword} onChange={event => changeHandler(event,'cpassword')} className="form-control" placeholder="Re-type Password" /></li>
                                {/* <li className='col-lg-5'><button type="Submit" class="btn btn-primary btn1">Save and Proceed to Payment</button></li> */}
                                <li className='col-lg-5'>{loading? loading : <a href='#' onClick={event => submitHandler(event)} class="btn btn-primary btn1">Save and Proceed to Payment</a>}</li>
                            </ul>
                        </div>
                        <div className='col-lg-4 rightBillingSection'>
                            {currentPackage ? <div className='packageBoxBlack'>
                                <h4>Selected Subcription</h4>
                                <h3>{currentPackage.title}</h3>
                                <h5>Price</h5>
                                <p>${currentPackage.price}</p>
                            </div> : null}
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default (CustomerInformation);