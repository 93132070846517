import React, {useState,useEffect} from 'react';
import LeftPanel from './LeftPanel';
import $ from 'jquery';
import Table from 'react-bootstrap/Table';
import axios from './config/axios';

const boximg = require("./images/box_img.png");


const PlanPricing = () => { 
    const [currentUser,setCurrentUser] = useState(null);
    const [subscriptions,setSubscriptions] = useState([]);


    useEffect( () => {
        const _currentUser =  JSON.parse(localStorage.getItem("currentUser"));
        console.log(_currentUser);
        setCurrentUser({..._currentUser});

        axios.post("/purchasehistory",{user:_currentUser.user.id})
        .then(response => {
               setSubscriptions(response.data.data);
        })

        $(function(){
            $(".side-toggle").click(function() {
                $(".leftPanel").toggleClass("activeLeftPanel");
                $(".side-toggle").toggleClass("active");
            });
        });
    }, []);
    return (
        <>
            <div className='row adminContentBox'>
                <LeftPanel />
                <div className='col-lg-11 rightPanel'>
                    <button className="side-toggle" type="button"><span className='toggleSpan'></span></button>
                    <div className='rightpanelNav'>
                        {/* <span className='userImgTotal'><span className='userImg'><img src={userImg} alt='img' /></span><span className='userName'>Hello Rechard!</span></span> */}
                        {currentUser &&  <span className='userImgTotal'><span className='userName'>Hello {currentUser.user.first_name}!</span></span>}
                    </div>
                    <h1>Plan & Pricing</h1>
                    <section id="myaccountSec">
                        <div className="planDiv">
                            <h3>My Plan</h3>
                            {subscriptions[0] && <div className="planBox">
                                <div className="planBox_top">
                                    <ul>
                                        <li> 
                                            <img src={boximg} alt="img" />
                                            <label>CURRENT PLAN</label>
                                            {subscriptions[0].name} </li>
                                        <li>
                                            <label>PRICE</label>
                                            ${subscriptions[0].price} / year 
                                        </li>
                                    </ul>
                                </div>
                                <div className="planBox_bottom">
                                    <h4>The plan will auto-renew on <strong>{subscriptions[0].end_date}</strong></h4>
                                </div>
                            </div>}
                        </div>
                        <div className="planDiv">
                            <h3>Purchase History</h3>        
                        </div>
                        <div className='tableContentBox'>
                            <div className='overflowDiv'>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Plan Name</th>
                                            <th>Start Date</th>
                                            <th>Expire Date</th>
                                            <th>Amount</th>
                                            <th>Customer</th>
                                            <th>Current Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subscriptions.map(subscription => <tr>
                                            <td>{subscription.name}</td>
                                            <td>{subscription.start_date}</td>
                                            <td>{subscription.end_date}</td>
                                            <td>${subscription.price}</td>
                                            <td>{subscription.customer}</td>
                                            <td><span className={subscription.status === 'Active' ? 'activeSpan' : 'inactiveSpan'}>{subscription.status}</span></td>
                                        </tr>)}
                                        
                                      
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
}

export default PlanPricing;