import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const tikImg = require("./images/Tick.png");


const ThankPayment = () => { 
    const [currentPackage,setCurrentPackage] = useState(0);

    useEffect(() => {
      const price = JSON.parse(localStorage.getItem("purchasePackagePrice"));

      setCurrentPackage(price);

    },[]);

    return (
        <>
            <section id="thnaksSection">
      <div className='container'>

        <div className="row about-container">

          <div className="col-lg-12">
          
          <div className="thankDiv">
          
          <img src={tikImg} alt="" className="img-fluid" />
          
          <h5>Thank you for your payment!</h5>
          <p>A receipt for this transaction has been sent via email for your records.</p>
          
          <h4>Total Payment Amount</h4>
          {currentPackage && <h3>${currentPackage}</h3>}
          
          
          <div className="btngrp">
          <a href="#" className="btn1">Google Form</a>
          <a href="#" className="btn2">Calendar</a>
          
          </div>
          </div>
          </div>
        </div>
      </div>
    </section>
        </>
    );
}

export default ThankPayment;