import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import LeftPanel from './LeftPanel';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from 'jquery';
import { UserData } from './Data';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import axios from './config/axios';
import twitterIcon from  "./images/twitter-icon.png";
import instagramIcon from "./images/ig-icon.png";
import facebookIcon from "./images/fb-icon.png";
const userImg = require("./images/user-img.png");
// const audio = require("./audio/the-future-bass-15017.mp3");
const speaker = require("./images/speeaker.webp");



const PlayersListing = () => { 
    const [show, setShow] = useState(false);
    const [pageLengths,setPageLengths] = useState([25,50,100]);
    const [currentPageLength,setCurrentPageLength] = useState(25); 
    const [initial,setInitial] = useState("Loading players");
    const [searchQuery,setSearchQuery] = useState({player_name:'',school_name:'',state:'',position:'',class:''})
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [audioShow, setaudioShow] = useState(false);
    const audioModalClose = () => setaudioShow(false);
    const audioModalShow = () => setaudioShow(true);
    const [players,setPlayers] = useState([]);
    const [audio,setAudio] = useState(null);
    const [currentUser,setCurrentUser] = useState(null);
    const [positions,setPositions] = useState([]);
    const [states,setStates] = useState([]);
    const [classes,setClasses] = useState([]);


    const executeSearch = () => {
        handleClose();
        axios.post("/searchplayer",searchQuery)
        .then(response => {
            if (  $.fn.DataTable.isDataTable( '#example' ) ) {
                $('#example').DataTable().destroy();
            }
            if(response.data.status && response.data.status === "error"){
                setPlayers([]);
                setInitial("No Player Found");
            }else{ 
                setInitial(null);
                setPlayers(response.data.data);
                setTimeout(() => {
                    $('#example').DataTable({
                        order: [[1, 'desc']],
                        pageLength:currentPageLength
                    }); 
                },1500);
            }
        }).catch(error => {
            $('#example').DataTable().destroy();
            setPlayers([]);
            setInitial("No Player Found");
        });
    }

    const searchQueryHandler = (event,source) => {
        const query = {...searchQuery};
        query[source] = event.target.value;
        setSearchQuery({...query});
    }

    const executeAudio = (event,audioLink) => {
        event.preventDefault();
        setAudio(audioLink);
        setaudioShow(true);
    }


    useEffect( () => {
        const _currentUser =  JSON.parse(localStorage.getItem("currentUser"));
        console.log(_currentUser);
        setCurrentUser({..._currentUser});
        $(function(){
            $(".side-toggle").click(function() {
                $(".leftPanel").toggleClass("activeLeftPanel");
                $(".side-toggle").toggleClass("active");
            });  
        });
        axios.get("/player")
        .then(response => {
            
            setPlayers(response.data.data);
            console.log(response.data.data);
            setTimeout(() => {
                $('#example').DataTable({
                    order: [[1, 'desc']],
                    pageLength:currentPageLength
                }); 
            },1500);
        })

        
        axios.get("/position")
        .then(response => {
            const {data} = response.data;
            setPositions([...data]);
        })

        axios.get("/state")
        .then(response => {
            const {data} = response.data;
            setStates([...data]);
        })

        axios.get("/class")
        .then(response => {
            const {data} = response.data;
            
            setClasses([...data]);
        })




        $(document).ready(function () {
            
        });
    }, []);


    const pageLengthHandler = event => {
        const pagelength = parseInt(event.target.value);
        if (  $.fn.DataTable.isDataTable( '#example' ) ) {
            $('#example').DataTable().destroy();
        }
        setCurrentPageLength(pagelength);
        $('#example').DataTable({
            order: [[1, 'desc']],
            pageLength:pagelength
        });
    }


    return (
        <>
            <div className='row adminContentBox'>
                <LeftPanel />
                <div className='col-lg-11 rightPanel'>
                    <button className="side-toggle" type="button"><span className='toggleSpan'></span></button>
                    <div className='rightpanelNav'>
                        {/* <span className='userImgTotal'><span className='userImg'><img src={userImg} alt='img' /></span><span className='userName'>Hello Rechard!</span></span> */}
                        {currentUser &&  <span className='userImgTotal'><span className='userName'>Hello {currentUser.user.first_name}!</span></span>}
                       
                        <span className='searchPlayer' onClick={handleShow}><span>Search Player</span></span>
                    </div>
                    <h1>Players Listing</h1>
                   <div style={{margin:10}}>
                   Currently displaying&nbsp;&nbsp;
                    <select onChange={pageLengthHandler}>
                        {pageLengths.map(length => <option value={length}>{length}</option>)}
                    </select>&nbsp;&nbsp;records
                   </div>
                    <div className='tableContentBox'>
                        {/* <span className='shortBy'>Sort by</span> */}
                        <div className='overflowDiv'>
                            <Table id="example" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Class</th>
                                        <th>School</th>
                                        <th>Email Address</th>
                                        <th>Phone Number</th>
                                        <th>Rating</th>
                                        <th>Rank</th>
                                        <th>Position</th>
                                        <th>State</th>
                                        <th>Social Media</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {players.length >= 1 && players.map((item , i) => (
                                        <tr key={i}>
                                            <td><Link to={'/PlayerDetails/' + item.id}>{item.first_name}</Link></td>
                                            <td><Link to={'/PlayerDetails/' + item.id}>{item.last_name}</Link></td>
                                            <td>{item.class}</td>
                                            <td>{item.high_school}</td>
                                            <td>{item.email_address}</td>
                                            <td>{item.parent_number}</td>
                                            <td>{item.rating}</td>
                                            <td>{item.national_rank}</td>
                                            <td>{item.position}</td>
                                            <td>{item.state}</td>
                                            <td>
                                                <>
                                                    {item.twitter ? <a href={item.twitter} target="_blank"><img src={twitterIcon} alt='img' className='socialicon'/></a> : null}
                                                    {item.instagram ? <a href={item.instagram} target="_blank"><img src={instagramIcon} alt='img' className='socialicon'/></a> : null}
                                                    {item.facebook ? <a href={item.facebook} target="_blank"><img src={facebookIcon} alt='img' className='socialicon'/></a> : null}
                                                    {item.audio_link && <a href={item.audio_link} target="_blank"><img src={speaker} style={{maxHeight:20}} alt='img' className='socialicon'/></a>}
                                                </>
                                               
                                            </td>
                                        </tr>
                                    ))}
                                    {players.length <= 0  && initial && <tr><th colspan="11" style={{backgroundColor:'white'}}>{initial}</th></tr>}
                                </tbody>
                            </Table>                    
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose} className='playerSearchModal'>
                <Modal.Header closeButton>
                    <Modal.Title>Search Players</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className='cotactList row'>
                        <li className='col-lg-6'><input type="text" value={searchQuery.player_name} onChange={event => searchQueryHandler(event,'player_name')} className="form-control" placeholder="Player name" /></li>
                        <li className='col-lg-6'><input type="text" value={searchQuery.school_name} onChange={event => searchQueryHandler(event,'school_name')} className="form-control" placeholder="School name" /></li>
                        <li className='col-lg-4'>
                           
                            <select className="form-control" onChange={event => searchQueryHandler(event,'state')}>
                                <option>Select State</option>
                                {
                                    states.map(state => {
                                        
                                        return <option value={state.state}>{state.state}</option>
                                    })
                                }
                            </select>
                        </li>
                        <li className='col-lg-4'>
                            <select className="form-control" onChange={event => searchQueryHandler(event,'position')}>
                                <option>Select Position</option>
                                {
                                    positions.map(position => {
                                        
                                        return <option value={position.position}>{position.position}</option>
                                    })
                                }
                            </select>
                        </li>
                        <li className='col-lg-4'>
                            
                            <select className="form-control" onChange={event => searchQueryHandler(event,'class')}>
                                <option>Select Class</option>
                                {
                                    classes.map(_class => {
                                        
                                        return <option value={_class.class}>{_class.class}</option>
                                    })
                                }
                            </select>
                        </li>
                        <li className='col-lg-12'><button type="Submit" class="btn btn-primary" onClick={executeSearch}>Search</button></li>
                    </ul>
                </Modal.Body>
            </Modal>
            <Modal show={audioShow} onHide={audioModalClose} className='audioModal'>
                <Modal.Header closeButton>
                    <Modal.Title>Audio</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AudioPlayer autoPlay src={audio} onPlay={e => console.log("onPlay")} />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default PlayersListing;