import React, {useEffect,useState} from 'react';
import { NavLink } from "react-router-dom";

const LeftPanel = () => { 
    const [currentUser,setCurrentUser] = useState(null);

    useEffect(() => {

        const _currentUser =  JSON.parse(localStorage.getItem("currentUser"));
        console.log(_currentUser);
        setCurrentUser({..._currentUser});


    },[]);


    return (
        <>
            <div className='col-lg-1 leftPanel'>
                <ul className='leftNavList'>
                    <li>
                        <NavLink to='/Dashboard' className='listItem' as={NavLink}>
                            <span className='imgspan'></span>
                            <span className='textspan'>
                                {currentUser && parseInt(currentUser.user.role_id) !== 4 ? 'Reports' : 'Dashboard'}</span>
                        </NavLink>
                    </li>
                    {currentUser && parseInt(currentUser.user.role_id) !== 4 &&  <li>
                        <NavLink to='/PlayersListing' className='listItem' as={NavLink}>
                            <span className='imgspan'></span>
                            <span className='textspan'>Player List</span>
                        </NavLink>
                    </li>}
                   
                    <li>
                        <NavLink to='/PlanPricing' className='listItem' as={NavLink}>
                            <span className='imgspan'></span>
                            <span className='textspan'>Plan & Pricing</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/MyAccount' className='listItem' as={NavLink}>
                            <span className='imgspan'></span>
                            <span className='textspan'>My Account</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/' className='listItem' as={NavLink}>
                            <span className='imgspan'></span>
                            <span className='textspan'>Logout</span>
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default LeftPanel;