import React, {useState,useEffect} from 'react';
import {useParams} from 'react-router-dom'
import axios from './config/axios'
import Aos from 'aos';
const blogDetailsImg1 = require("./images/blog-details-img1.png");
const blogDetailsImg2 = require("./images/blog-details-img2.jpg");

const BlogDetails = (props) => { 
    const params = useParams();
    const [blog,setBlog] = useState(null);

    useEffect( () => {
        Aos.init();
        axios.get("/blogs")
        .then(response => {
            const {data} = response.data;
            const _blog = data.filter(d => d.id == params.id)[0];
            setBlog({..._blog});
        })
    
    
    
    }, []);
    return (
        <>
            {blog ?  <><div style={{maxWidth:'75%',margin:'30px auto 10px auto',textAlign:'center'}}> <img src={blog.image} alt="img" style={{}} /></div><div className='blogDetailsContent'>
                <div className='container' style={{margin:'0 auto',maxWidth:'90%'}}>
                    <h4>{blog.created_at}</h4>
                    <div dangerouslySetInnerHTML={{__html:blog.description}}></div>
                </div>
            </div></> : null}
           
        </>
    );
}

export default BlogDetails;